import React from 'react';
import { useWeather } from '../providers/weatherContext';
import Loader from './Loader.tsx';
import { Accordion } from 'react-bootstrap';


const WeatherOverview = () => {
    const { weatherData } = useWeather();
    const error = weatherData.error;
    let overview;
    if (weatherData.loading) {
        return (
          <>
             <Loader numOfCols={8} />
           </>
         );
      }
    if(!error) {
        overview = weatherData.data[1].overview;
    }
    
    const renderOverview = () => {
        
        if(!error) {
            return (
                <Accordion flush className="justify-content-center d-flex row">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Weather Summary</Accordion.Header>
                        <Accordion.Body className='overview-content bg-dark'>
                            {overview}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            
            );
        }

    };
    

    return <>{renderOverview()}</>;

};
export default WeatherOverview;