import React from 'react';
import { getLocalStorageItem } from '../utils';
import { LOCAL_STORAGE_KEY_GPS_POSITION } from '../utils/constants.js';
import Loader from './Loader.tsx';
import * as Utils from '../utils';
import * as Constants from '../utils/constants';


import { useWeather } from '../providers/weatherContext';


const Radar =  () => {
  let lat;
  let lon;
  let positionLocalStorage = getLocalStorageItem(LOCAL_STORAGE_KEY_GPS_POSITION);
  if(positionLocalStorage === null) {

    if (Utils.getURLParam(Constants.URL_PARAM_LAT) && Utils.getURLParam(Constants.URL_PARAM_LON)) {
      lat = Utils.getURLParam(Constants.URL_PARAM_LAT);
      lon = Utils.getURLParam(Constants.URL_PARAM_LON);
      
    } else {
      const { weatherData, error} = useWeather();
      if (weatherData.loading) {
        return (
          <>
          <Loader numOfCols={8} />
          <Loader numOfCols={10} />
            <Loader numOfCols={10} />
            <Loader numOfCols={5} />
            <Loader numOfCols={7} />
            <Loader numOfCols={9} />
            <Loader numOfCols={8} />
          </>
        );
      }
    }
  }

  positionLocalStorage = getLocalStorageItem(LOCAL_STORAGE_KEY_GPS_POSITION);
 
  if (positionLocalStorage !== null) {
    lat = positionLocalStorage.lat;
    lon = positionLocalStorage.lon;
  }
  
  const renderRadarIfNoError = () => {
    if (lat !== null && lon !== null) {
      let embedLink = 'https://embed.windy.com/embed.html?type=map&location=coordinates&metricRain=default&metricTemp=default&metricWind=default&zoom=8&overlay=radar&product=ecmwf&level=surface&lat='+lat+'&lon='+lon;
      return (
        <>
          <iframe
            allow='fullscreen'
            style={{
              border: 0,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              width: '100%',
              height: '500px',
            }}
            src={embedLink}
          ></iframe>
        </>
      )
    }
  }
  
  return (
    <div className='pt-3'>
       { renderRadarIfNoError() }
     </div>
  );
};

export default Radar;
