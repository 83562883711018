import React from 'react';
import { getLocalStorageItem, getURLLocation } from '../utils';
import * as Constants from '../utils/constants';
import * as Utils from '../utils';
import { LOCAL_STORAGE_KEY_GPS_POSITION } from '../utils/constants.js';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { useWeather } from '../providers/weatherContext';

function Links() {
  
  const getRadarLink = () => {
    let lat = null;
    let lon = null;
    let positionLocalStorage = getLocalStorageItem(LOCAL_STORAGE_KEY_GPS_POSITION);
    if (Utils.getURLParam(Constants.URL_PARAM_LAT) && Utils.getURLParam(Constants.URL_PARAM_LON)) {
      lat = Utils.getURLParam(Constants.URL_PARAM_LAT);
      lon = Utils.getURLParam(Constants.URL_PARAM_LON);
      
    } else if(positionLocalStorage === null || lat === null || lon === null) {

        const { weatherData, error} = useWeather();
        if (weatherData.loading) {
          return (
            <>
            </>
          );
        }
      
    }
    if(positionLocalStorage !== null) {
      lat = positionLocalStorage.lat;
      lon = positionLocalStorage.lon;
    }
    let radarLink = '/radar'
    if(lat !== null && lon !== null) {
      radarLink = `/radar?${Constants.URL_PARAM_LAT}=${lat}&${Constants.URL_PARAM_LON}=${lon}`;
    }
    return radarLink;
  }
  
  const generateLinks = () => {
    
    let linkId = 0;
    return [
      { id: linkId++, image: require('../assets/img/ctv-news.jpg'), linkURL: 'https://calgary.ctvnews.ca/' },
      { id: linkId++, image: require('../assets/img/ec-weather-1.jpg'), linkURL: 'https://weather.gc.ca/en/location/index.html?coords=51.046,-114.057' },
      { id: linkId++, image: require('../assets/img/windy.png'), linkURL: getRadarLink() },
      { id: linkId++, image: require('../assets/img/660-news.jpg'), linkURL: 'http://660news.com' },
      { id: linkId++, image: require('../assets/img/cbc-calgary-1.jpg'), linkURL: 'http://cbc.ca/news/canada/calgary' },
      { id: linkId++, image: require('../assets/img/global-calgary-1.jpg'), linkURL: 'https://globalnews.ca/calgary/' },
      { id: linkId++, image: require('../assets/img/google-1.jpg'), linkURL: 'http://news.google.com' },
      { id: linkId++, image: require('../assets/img/slashdot-icon.png'), linkURL: 'http://slashdot.org' },
    ];
  };
    

  return (
    <Row className='justify-content-center'>
      {generateLinks().map((linkdetails, index) => (
        <Col xs='3' md='1' className='mb-3' key={linkdetails.id}><a href={linkdetails.linkURL}><img className='img-thumbnail img-fluid' src={linkdetails.image} /></a></Col>
      ))}
    </Row>
  );
}
export default Links;
